<script context="module">
export function load({error, status}) {
  return {
    props: {
      error,
      status
    }
  }
}
</script>

<script>
  export let status;
  export let error;

  const MESSAGE = 'error loading dynamically imported module';

  // Reload if the new version of script was deployed
  if (error && error.message === MESSAGE) {
    if (document) {
      document.location.reload();
    }
  }

  if (error && error.status === 404) {
    alert(404);
  }

  const dev = import.meta.env.NODE_ENV === 'development';
</script>

<style>
  h1,
  p {
    margin: 0 auto;
  }

  h1 {
    font-size: 2.8em;
    font-weight: 700;
    margin: 0 0 0.5em 0;
  }

  p {
    margin: 1em auto;
  }

  @media (min-width: 480px) {
    h1 {
      font-size: 4em;
    }
  }
</style>

<svelte:head>
  <title>{status}</title>
</svelte:head>

<div class="container">
  <h1>{status}</h1>

  {#if error}
    <p>{error.message}</p>

    {#if dev && error.stack}
      <pre>{error.stack}</pre>
    {/if}
  {/if}
</div>
